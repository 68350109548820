import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import oval1 from "@images/oval.svg"
import oval2 from "@images/oval-2.svg"
import oval3 from "@images/oval-3.svg"

// Inline styling

const termsContent = {
  padding: "10rem 0 5rem 0",
  position: "relative",
  overflow: "hidden",
  // backgroundImage: `url(${faqContentBg})`,
  // backgroundRepeat: "no-repeat",
  // backgroundSize: "cover",
  // backgroundPosition: "top",
  // backgroundAttachment: "local",
}

const heroHeader = {
  fontSize: 40,
  marginBottom: "2rem",
}

// end of styling

const TermsofService = ({location}) => {
  return (
    <>
      <Layout location={location}>
        <SEO title="Terms of Service" description="" />

        <div className="innerpages-hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-text-wrapper text-center">
                  <h2 style={heroHeader}>Terms of Service</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="terms-content" style={termsContent}>
          <img className="oval-svg" src={oval1} alt="Oval svg" />
          <img className="oval-2" src={oval2} alt="Oval svg" />
          <img className="oval-3" src={oval3} alt="Oval svg" />
          <div className="container">
            <div className="row">
              <div className="col-10 m-auto">
                <div>
                  <h1>VENTURETACTICS, LLC.</h1>
                  <p>Date Effective: January, 2019</p>
                  <div>
                    <h4>General</h4>
                    <p>
                      This website, together with corresponding social media
                      accounts and mobile applications (collectively, the
                      "Site") is owned and operated by VENTURETACTICS LLC.
                      (“Omnipresence Engine”, “VENTURETACTICS”, "we", or "us").
                      By using the Site, you agree to be bound by these Terms of
                      Service and to use the Site in accordance with these Terms
                      of Service, our Privacy Policy, our Refund Policy and any
                      additional terms and conditions that may apply to specific
                      sections of the Site or to products and services available
                      through the Site or from VENTURETACTICS. Accessing the
                      Site, in any manner, whether automated or otherwise,
                      constitutes use of the Site and your agreement to be bound
                      by these Terms of Service. We reserve the right to change
                      these Terms of Service or to impose new conditions on use
                      of the Site, from time to time, in which case we will post
                      the revised Terms of Service on this website. By
                      continuing to use the Site after we post any such changes,
                      you accept the Terms of Service, as modified.
                    </p>
                  </div>
                  <div>
                    <h4>Intellectual Property Rights</h4>
                    <p>
                      Our Limited License to You. This Site and all the
                      materials available on the Site are the property of us
                      and/or our affiliates or licensors, and are protected by
                      copyright, trademark, and other intellectual property
                      laws. The Site is provided solely for your personal
                      noncommercial use. You may not use the Site or the
                      materials available on the Site in a manner that
                      constitutes an infringement of our rights or that has not
                      been authorized by us. More specifically, unless
                      explicitly authorized in these Terms of Service or by the
                      owner of the materials, you may not modify, copy,
                      reproduce, republish, upload, post, transmit, translate,
                      sell, create derivative works, exploit, or distribute in
                      any manner or medium (including by email or other
                      electronic means) any material from the Site. You may,
                      however, from time to time, download and/or print one copy
                      of individual pages of the Site for your personal,
                      non-commercial use, provided that you keep intact all
                      copyright and other proprietary notices.
                    </p>
                    <p>
                      Your License to Us. By posting or submitting any material
                      (including, without limitation, comments, blog entries,
                      Facebook postings, photos and videos) to us via the Site,
                      internet groups, social media venues, or to any of our
                      staff via email, text or otherwise, you are representing:
                      (i) that you are the owner of the material, or are making
                      your posting or submission with the express consent of the
                      owner of the material; and (ii) that you are thirteen
                      years of age or older. In addition, when you submit,
                      email, text or deliver or post any material, you are
                      granting us, and anyone authorized by us, a royalty-free,
                      perpetual, irrevocable, non-exclusive, unrestricted,
                      worldwide license to use, copy, modify, transmit, sell,
                      exploit, create derivative works from, distribute, and/or
                      publicly perform or display such material, in whole or in
                      part, in any manner or medium, now known or hereafter
                      developed, for any purpose. The foregoing grant shall
                      include the right to exploit any proprietary rights in
                      such posting or submission, including, but not limited to,
                      rights under copyright, trademark, service mark or patent
                      laws under any relevant jurisdiction. Also, in connection
                      with the exercise of such rights, you grant us, and anyone
                      authorized by us, the right to identify you as the author
                      of any of your postings or submissions by name, email
                      address or screen name, as we deem appropriate.
                    </p>
                    <p>
                      You acknowledge and agree that any contributions
                      originally created by you for us shall be deemed a “work
                      made for hire” when the work performed is within the scope
                      of the definition of a work made for hire in Section 101
                      of the United States Copyright Law, as amended. As such,
                      the copyrights in those works shall belong to
                      VENTURETACTICS from their creation. Thus, VENTURETACTICS
                      shall be deemed the author and exclusive owner thereof and
                      shall have the right to exploit any or all of the results
                      and proceeds in any and all media, now known or hereafter
                      devised, throughout the universe, in perpetuity, in all
                      languages, as VENTURETACTICS determines. In the event that
                      any of the results and proceeds of your submissions
                      hereunder are not deemed a “work made for hire” under
                      Section 101 of the Copyright Act, as amended, you hereby,
                      without additional compensation, irrevocably assign,
                      convey and transfer to VENTURETACTICS all proprietary
                      rights, including without limitation, all copyrights and
                      trademarks throughout the universe, in perpetuity in every
                      medium, whether now known or hereafter devised, to such
                      material and any and all right, title and interest in and
                      to all such proprietary rights in every medium, whether
                      now known or hereafter devised, throughout the universe,
                      in perpetuity. Any posted material which are reproductions
                      of prior works by you shall be co-owned by us.
                    </p>
                    <p>
                      You acknowledge that VENTURETACTICS has the right but not
                      the obligation to use and display any postings or
                      contributions of any kind and that VENTURETACTICS may
                      elect to cease the use and display of any such materials
                      (or any portion thereof), at any time for any reason
                      whatsoever.
                    </p>
                    <p>
                      Limitations on Linking and Framing. You may establish a
                      hypertext link to the Site so long as the link does not
                      state or imply any sponsorship of your site by us or by
                      the Site. However, you may not, without our prior written
                      permission, frame or inline link any of the content of the
                      Site, or incorporate into another website or other service
                      any of our material, content or intellectual property.
                    </p>
                  </div>
                  <div>
                    <h4>Disclaimers</h4>
                    <p>
                      Throughout the Site, we may provide links and pointers to
                      Internet sites maintained by third parties. Our linking to
                      such third-party sites does not imply an endorsement or
                      sponsorship of such sites, or the information, products or
                      services offered on or through the sites. In addition,
                      neither we nor affiliates operate or control in any
                      respect any information, products or services that third
                      parties may provide on or through the Site or on websites
                      linked to by us on the Site.
                    </p>
                    <p>
                      If applicable, any opinions, advice, statements, services,
                      offers, or other information or content expressed or made
                      available by third parties, including information
                      providers, are those of the respective authors or
                      distributors, and not VENTURETACTICS . Neither
                      VENTURETACTICS nor any third-party provider of information
                      guarantees the accuracy, completeness, or usefulness of
                      any content. Furthermore, VENTURETACTICS neither endorses
                      nor is responsible for the accuracy and reliability of any
                      opinion, advice, or statement made on any of the Sites by
                      anyone other than an authorized VENTURETACTICS
                      representative while acting in his/her official capacity.
                    </p>
                    <p>
                      THE INFORMATION, PRODUCTS AND SERVICES OFFERED ON OR
                      THROUGH THE SITE AND BY VENTURETACTICS AND ANY THIRD-PARTY
                      SITES ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY
                      KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
                      PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL
                      WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
                      TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
                      A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE SITE OR
                      ANY OF ITS FUNCTIONS WILL BE UNINTERRUPTED OR ERROR-FREE,
                      THAT DEFECTS WILL BE CORRECTED, OR THAT ANY PART OF THIS
                      SITE, INCLUDING BULLETIN BOARDS, OR THE SERVERS THAT MAKE
                      IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL
                      COMPONENTS.
                    </p>
                    <p>
                      WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING
                      THE USE OR THE RESULTS OF THE USE OF THE SITE OR MATERIALS
                      ON THIS SITE OR ON THIRD-PARTY SITES IN TERMS OF THEIR
                      CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR
                      OTHERWISE.
                    </p>
                    <p>
                      You agree at all times to defend, indemnify and hold
                      harmless VENTURETACTICS its affiliates, their successors,
                      transferees, assignees and licensees and their respective
                      parent and subsidiary companies, agents, associates,
                      officers, directors, shareholders and employees of each
                      from and against any and all claims, causes of action,
                      damages, liabilities, costs and expenses, including legal
                      fees and expenses, arising out of or related to your
                      breach of any obligation, warranty, representation or
                      covenant set forth herein.
                    </p>
                  </div>
                  <div>
                    <h4>Online Commerce</h4>
                    <p>
                      Certain sections of the Site may allow you to purchase
                      many different types of products and services online that
                      are provided by third parties. We are not responsible for
                      the quality, accuracy, timeliness, reliability or any
                      other aspect of these products and services. If you make a
                      purchase from a merchant on the Site or on a site linked
                      to by the Site, the information obtained during your visit
                      to that merchant's online store or site, and the
                      information that you give as part of the transaction, such
                      as your credit card number and contact information, may be
                      collected by both the merchant and us. A merchant may have
                      privacy and data collection practices that are different
                      from ours. We have no responsibility or liability for
                      these independent policies. In addition, when you purchase
                      products or services on or through the Site, you may be
                      subject to additional terms and conditions that
                      specifically apply to your purchase or use of such
                      products or services. For more information regarding a
                      merchant, its online store, its privacy policies, and/or
                      any additional terms and conditions that may apply, visit
                      that merchant's website and click on its information links
                      or contact the merchant directly. You release us and our
                      affiliates from any damages that you incur, and agree not
                      to assert any claims against us or them, arising from your
                      purchase or use of any products or services made available
                      by third parties through the Site.
                    </p>
                    <p>
                      Your participation, correspondence or business dealings
                      with any third party found on or through our Site,
                      regarding payment and delivery of specific goods and
                      services, and any other terms, conditions, representations
                      or warranties associated with such dealings, are solely
                      between you and such third party. You agree that
                      VENTURETACTICS shall not be responsible or liable for any
                      loss, damage, or other matters of any sort incurred as the
                      result of such dealings.
                    </p>
                    <p>
                      You agree to be financially responsible for all purchases
                      made by you or someone acting on your behalf through the
                      Site. You agree to use the Site and to purchase services
                      or products through the Site for legitimate,
                      non-commercial purposes only. You also agree not to make
                      any purchases for speculative, false or fraudulent
                      purposes or for the purpose of anticipating demand for a
                      particular product or service. You agree to only purchase
                      goods or services for yourself or for another person for
                      whom you are legally permitted to do so. When making a
                      purchase for a third party that requires you to submit the
                      third party's personal information to us or a merchant,
                      you represent that you have obtained the express consent
                      of such third party to provide such third party's personal
                      information.
                    </p>
                  </div>
                  <div>
                    <h4>Interactive Features</h4>
                    <p>
                      This Site may include a variety of features, such as
                      bulletin boards, web logs, chat rooms, and email services,
                      which allow feedback to us and real-time interaction
                      between users, and other features which allow users to
                      communicate with others. Responsibility for what is posted
                      on bulletin boards, web logs, chat rooms, and other public
                      posting areas on the Site, or sent via any email services
                      on the Site, lies with each user - you alone are
                      responsible for the material you post or send. We do not
                      control the messages, information or files that you or
                      others may provide through the Site. It is a condition of
                      your use of the Site that you do not:
                    </p>
                    <ul>
                      <li>
                        <p>
                          Restrict or inhibit any other user from using and
                          enjoying the Site.
                        </p>
                      </li>
                      <li>
                        Use the Site to impersonate any person or entity, or
                        falsely state or otherwise misrepresent your affiliation
                        with a person or entity.
                      </li>
                      <li>
                        Interfere with or disrupt any servers or networks used
                        to provide the Site or its features, or disobey any
                        requirements, procedures, policies or regulations of the
                        networks we use to provide the Site.
                      </li>
                      <li>
                        Use the Site to instigate or encourage others to commit
                        illegal activities or cause injury or property damage to
                        any person.
                      </li>
                      <li>
                        Gain unauthorized access to the Site, or any account,
                        computer system, or network connected to this Site, by
                        means such as hacking, password mining or other illicit
                        means.
                      </li>
                      <li>
                        Obtain or attempt to obtain any materials or information
                        through any means not intentionally made available
                        through this Site.
                      </li>
                      <li>
                        Use the Site to post or transmit any unlawful,
                        threatening, abusive, libelous, defamatory, obscene,
                        vulgar, pornographic, profane or indecent information of
                        any kind, including without limitation any transmissions
                        constituting or encouraging conduct that would
                        constitute a criminal offense, give rise to civil
                        liability or otherwise violate any local, state,
                        national or international law.
                      </li>
                      <li>
                        Use the Site to post or transmit any information,
                        software or other material that violates or infringes
                        upon the rights of others, including material that is an
                        invasion of privacy or publicity rights or that is
                        protected by copyright, trademark or other proprietary
                        right, or derivative works with respect thereto, without
                        first obtaining permission from the owner or rights
                        holder.
                      </li>
                      <li>
                        Use the Site to post or transmit any information,
                        software or other material that contains a virus or
                        other harmful component.
                      </li>
                      <li>
                        Use the Site to post, transmit or in any way exploit any
                        information, software or other material for commercial
                        purposes, or that contains advertising.
                      </li>
                      <li>
                        Use the Site to advertise or solicit to anyone to buy or
                        sell products or services, or to make donations of any
                        kind, without our express written approval.
                      </li>
                      <li>
                        Gather for marketing purposes any email addresses or
                        other personal information that has been posted by other
                        users of the Site.
                      </li>
                    </ul>
                    <p>
                      VENTURETACTICS may host message boards, chats and other
                      private/public forums on its Sites and on other platforms.
                      Any user failing to comply with the terms and conditions
                      of this Agreement may be expelled from and refused
                      continued access to, the message boards, groups, chats or
                      other such forums in the future. VENTURETACTICS or its
                      designated agents may remove or alter any user-created
                      content at any time for any reason. Message boards, chats
                      and other public forums are intended to serve as
                      discussion centers for users and subscribers. Information
                      and content posted within these public forums may be
                      provided by VENTURETACTICS staff, VENTURETACTICS’s outside
                      contributors, or by users not connected with
                      VENTURETACTICS, some of whom may employ anonymous user
                      names. VENTURETACTICS expressly disclaims all
                      responsibility and endorsement and makes no representation
                      as to the validity of any opinion, advice, information or
                      statement made or displayed in these forums by third
                      parties, nor are we responsible for any errors or
                      omissions in such postings, or for hyperlinks embedded in
                      any messages. Under no circumstances will we, our
                      affiliates, suppliers or agents be liable for any loss or
                      damage caused by your reliance on information obtained
                      through these forums. The opinions expressed in these
                      forums are solely the opinions of the participants, and do
                      not reflect the opinions of VENTURETACTICS or any of its
                      subsidiaries or affiliates.
                    </p>
                    <p>
                      VENTURETACTICS has no obligation whatsoever to monitor any
                      of the content or postings on the message boards, chat
                      rooms or other public forums on the Sites. However, you
                      acknowledge and agree that we have the absolute right to
                      monitor the same at our sole discretion. In addition, we
                      reserve the right to alter, edit, refuse to post or remove
                      any postings or content, in whole or in part, for any
                      reason and to disclose such materials and the
                      circumstances surrounding their transmission to any third
                      party in order to satisfy any applicable law, regulation,
                      legal process or governmental request and to protect
                      ourselves, our clients, sponsors, users and visitors.
                    </p>
                  </div>
                  <div>
                    <h4>Registration</h4>
                    <p>
                      To access certain features of the Site, we may ask you to
                      provide certain demographic information including your
                      gender, year of birth, zip code and country. In addition,
                      if you elect to sign-up for a particular feature of the
                      Site, such as chat rooms, web logs, or bulletin boards,
                      you may also be asked to register with us on the form
                      provided and such registration may require you to provide
                      personally identifiable information such as your name and
                      email address. You agree to provide true, accurate,
                      current and complete information about yourself as
                      prompted by the Site's registration form. If we have
                      reasonable grounds to suspect that such information is
                      untrue, inaccurate, or incomplete, we have the right to
                      suspend or terminate your account and refuse any and all
                      current or future use of the Site (or any portion
                      thereof). Our use of any personally identifiable
                      information you provide to us as part of the registration
                      process is governed by the terms of our Privacy Policy.
                    </p>
                  </div>
                  <div>
                    <h4>Passwords</h4>
                    <p>
                      To use certain features of the Site, you will need a
                      username and password, which you will receive through the
                      Site's registration process. You are responsible for
                      maintaining the confidentiality of the password and
                      account, and are responsible for all activities (whether
                      by you or by others) that occur under your password or
                      account. You agree to notify us immediately via email to
                      Tyler@venturetactics.com of any unauthorized use of your
                      password or account or any other breach of security, and
                      to ensure that you exit from your account at the end of
                      each session. We cannot and will not be liable for any
                      loss or damage arising from your failure to protect your
                      password or account information.
                    </p>
                  </div>
                  <div>
                    <h4>Limitation of Liability</h4>
                    <p>
                      UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO,
                      NEGLIGENCE, SHALL WE, OUR SUBSIDIARY AND PARENT COMPANIES
                      OR AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT,
                      INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT
                      FROM THE USE OF, OR THE INABILITY TO USE, THE SITE,
                      INCLUDING OUR MESSAGING, BLOGS, COMMENTS OF OTHERS, BOOKS,
                      EMAILS, PRODUCTS, OR SERVICES, OR THIRD-PARTY MATERIALS,
                      PRODUCTS, OR SERVICES MADE AVAILABLE THROUGH THE SITE OR
                      BY US IN ANY WAY, EVEN IF WE ARE ADVISED BEFOREHAND OF THE
                      POSSIBILITY OF SUCH DAMAGES. (BECAUSE SOME STATES DO NOT
                      ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN CATEGORIES OF
                      DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN
                      SUCH STATES, OUR LIABILITY AND THE LIABILITY OF OUR
                      SUBSIDIARY AND PARENT COMPANIES OR AFFILIATES IS LIMITED
                      TO THE FULLEST EXTENT PERMITTED BY SUCH STATE LAW.) YOU
                      SPECIFICALLY ACKNOWLEDGE AND AGREE THAT WE ARE NOT LIABLE
                      FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY
                      USER. IF YOU ARE DISSATISFIED WITH THE SITE, ANY
                      MATERIALS, PRODUCTS, OR SERVICES ON THE SITE, OR WITH ANY
                      OF THE SITE'S TERMS AND CONDITIONS, YOUR SOLE AND
                      EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE AND THE
                      PRODUCTS, SERVICES AND/OR MATERIALS.
                    </p>
                    <p>
                      THIS SITE IS CONTINUALLY UNDER DEVELOPMENT AND
                      VENTURETACTICS MAKES NO WARRANTY OF ANY KIND, IMPLIED OR
                      EXPRESS, AS TO ITS ACCURACY, COMPLETENESS OR
                      APPROPRIATENESS FOR ANY PURPOSE.
                    </p>
                    <p>WITH REGARDS TO FINANCIAL CONTENT ON THE SITE:</p>
                    <p>
                      NEITHER VENTURETACTICS NOR ITS OWNERS, OFFICERS,
                      DIRECTORS, EMPLOYEES, SUBSIDIARIES, AFFILIATES, LICENSORS,
                      SERVICE PROVIDERS, CONTENT PROVIDERS AND AGENTS ARE
                      FINANCIAL ADVISERS AND NOTHING CONTAINED ON THE SITE IS
                      INTENDED TO BE OR TO BE CONSTRUED AS FINANCIAL ADVICE.
                    </p>
                    <p>
                      VENTURETACTICS IS NOT AN INVESTMENT ADVISORY SERVICE, IS
                      NOT AN INVESTMENT ADVISER, AND DOES NOT PROVIDE
                      PERSONALIZED FINANCIAL ADVICE OR ACT AS A FINANCIAL
                      ADVISOR. THIS SITE EXISTS FOR EDUCATIONAL PURPOSES ONLY,
                      AND THE MATERIALS AND INFORMATION CONTAINED HEREIN ARE FOR
                      GENERAL INFORMATIONAL PURPOSES ONLY.
                    </p>
                    <p>
                      THE EDUCATION AND INFORMATION PRESENTED HEREIN IS INTENDED
                      FOR A GENERAL AUDIENCE AND DOES NOT PURPORT TO BE, NOR
                      SHOULD IT BE CONSTRUED AS, SPECIFIC ADVICE TAILORED TO ANY
                      INDIVIDUAL. YOU ARE ENCOURAGED TO DISCUSS ANY
                      OPPORTUNITIES WITH YOUR ATTORNEY, ACCOUNTANT, FINANCIAL
                      PROFESSIONAL OR OTHER ADVISOR.
                    </p>
                    <p>+++++++++++++++++</p>
                    <p>
                      The information contained in this program (including but
                      not limited to content in any format) is based on sources
                      and information reasonably believed to be accurate as of
                      the time it was recorded or created. However, this
                      material deals with topics that are constantly changing
                      and are subject to ongoing changes related to technology
                      and the market place as well as legal and related
                      compliance issues. Therefore, the completeness and current
                      accuracy of the materials cannot be guaranteed. These
                      materials do not constitute legal, compliance, financial,
                      tax, accounting, or related advice.
                    </p>
                    <p>
                      The end user of this information should therefore use the
                      contents of this program and the materials as a general
                      guideline and not as the ultimate source of current
                      information and when appropriate the user should consult
                      their own legal, accounting or other advisors.
                    </p>
                    <p>
                      Any case studies, examples, illustrations cannot guarantee
                      that the user will achieve similar results. In fact, your
                      results may vary significantly and factors such as your
                      market, personal effort and many other circumstances may
                      and will cause results to vary.
                    </p>
                    <p>
                      THE SERVICES RENDERED IS SOLD AND PROVIDED ON AN “AS IS”
                      BASIS. VENTURETACTICS DOES NOT PROMISE OR GUARANTEE ANY
                      INCOME OR PARTICULAR RESULT FROM YOUR USE OF THE
                      INFORMATION CONTAINED HEREIN. THOSE RESULTS ARE YOUR
                      RESPONSIBILITY AS THE END USER OF THE PRODUCT. (SOME
                      STATES DO NOT ALLOW LIMITED WARRANTIES, SO THIS MAY NOT
                      APPLY TO YOU.) IN PARTICULAR, VENTURETACTICS SHALL NOT BE
                      LIABLE TO USER OR ANY OTHER PARTY FOR ANY DAMAGES, OR
                      COSTS, OF ANY CHARACTER INCLUDING BUT NOT LIMITED TO
                      DIRECT OR INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR
                      OTHER COSTS OR DAMAGES, IN EXCESS OF THE PURCHASE PRICE OF
                      THE PRODUCT OR SERVICES. THESE LIMITATIONS MAY BE AFFECTED
                      BY THE LAWS OF PARTICULAR STATES AND JURISDICTIONS AND AS
                      SUCH MAY BE APPLIED IN A DIFFERENT MANNER TO A PARTICULAR
                      USER.
                    </p>
                  </div>
                  <div>
                    <h4>Termination</h4>
                    <p>
                      We may cancel or terminate your right to use the Site or
                      any part of the Site at any time without notice. In the
                      event of cancellation or termination, you are no longer
                      authorized to access the part of the Site affected by such
                      cancellation or termination. The restrictions imposed on
                      you with respect to material downloaded from the Site, and
                      the disclaimers and limitations of liabilities set forth
                      in these Terms of Service, shall survive.
                    </p>
                  </div>
                  <div>
                    <h4>Refund Policy</h4>
                    <p>
                      Your purchase of a product or service or ticket to an
                      event may or may not provide for any refund. Each specific
                      product, service, event or course will specify its own
                      refund policy.
                    </p>
                  </div>
                  <div>
                    <h4>Digital Millennium Copyright Act:</h4>
                    <p>
                      The Digital Millennium Copyright Act of 1998 (the "DMCA")
                      provides recourse for copyright owners who believe that
                      material appearing on the Internet infringes their rights
                      under the U.S. copyright law. If you believe in good faith
                      that materials hosted by VENTURETACTICS infringe your
                      copyright, you, or your agent may send to VENTURETACTICS a
                      notice requesting that the material be removed or access
                      to it be blocked. Any notification by a copyright owner or
                      a person authorized to act on its behalf that fails to
                      comply with requirements of the DMCA shall not be
                      considered sufficient notice and shall not be deemed to
                      confer upon VENTURETACTICS actual knowledge of facts or
                      circumstances from which infringing material or acts are
                      evident. If you believe in good faith that a notice of
                      copyright infringement has been wrongly filed against you,
                      the DMCA permits you to send to VENTURETACTICS a
                      counter-notice. All notices and counter notices must meet
                      the then current statutory requirements imposed by the
                      DMCA; see http://www.loc.gov/copyright for details.
                      VENTURETACTICS's Copyright Agent for notice of claims of
                      copyright infringement or counter notices can be reached
                      as follows: Tyler@venturetactics.com
                    </p>
                  </div>
                  <div>
                    <h4>Miscellaneous</h4>
                    <p>
                      This Agreement shall be binding upon and inure to the
                      benefit of VENTURETACTICS and our respective assigns,
                      successors, heirs, and legal representatives. Neither this
                      Agreement nor any rights hereunder may be assigned without
                      the prior written consent of VENTURETACTICS
                      Notwithstanding the foregoing, all rights and obligations
                      under this Agreement may be freely assigned by
                      VENTURETACTICS to any affiliated entity or any of its
                      wholly owned subsidiaries.
                    </p>
                  </div>
                  <div>
                    <h4>Dispute Resolution</h4>
                    <p>
                      These Terms of Use shall be governed by and construed in
                      accordance with the laws of the Province of Newfoundland,
                      Canada and any dispute shall be subject to binding
                      arbitration in Newfoundland. If any provision of this
                      agreement shall be unlawful, void or for any reason
                      unenforceable, then that provision shall be deemed
                      severable from this agreement and shall not affect the
                      validity and enforceability of any remaining provisions.
                    </p>
                  </div>
                  <div>
                    <h4>Class Action Waiver</h4>
                    <p>
                      You may only resolve disputes with us on an individual
                      basis, and may not bring a claim as a plaintiff or a class
                      member in a class, consolidated, or representative action.
                      Class arbitrations, class actions, private attorney
                      general actions, and consolidation with other arbitrations
                      aren't allowed.
                    </p>
                    <p>
                      The arbitrator may not consolidate more than one person’s
                      claims, and may not otherwise preside over any form of a
                      class or representative proceeding or claims (such as a
                      class action, consolidated action or private attorney
                      general action) unless all relevant parties specifically
                      agree to do so following initiation of the arbitration.
                    </p>
                  </div>
                  <div>
                    <h4>Severability</h4>
                    <p>
                      If any clause within this Provision (other than the Class
                      Action Waiver clause above) is found to be illegal or
                      unenforceable, that clause will be severed from this
                      Provision, and the remainder of this Provision will be
                      given full force and effect. If the Class Action Waiver
                      clause is found to be illegal or unenforceable, this
                      entire Provision will be unenforceable and the dispute
                      will be decided by a court.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TermsofService

// export const faqQuery = graphql`
//   query {
//     sanityCompanyInfo {
//       id
//       faq {
//         _key
//         title
//         body
//       }
//     }
//   }
// `
